.videofooter {
    object-fit: cover;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
} 

.footer {
    background: $secondary;
    padding: 1.5rem 0;
    position:relative;
    padding: 115px 0 100px;

    @media only screen and (max-width: 768px) {
        .footer {
            padding: 50px 0 25px;
        }
    }

    @media only screen and (max-width: 991px) {
        .footer {
            padding: 60px 0 30px;
        }
    }

    @media only screen and (max-width: 1024px) {
        .footer {
            padding: 60px 0 30px;
        }
    }


    .videofooter {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    } 
    .footer_title{
    color: #f6f7fb;
    font-size: 50px;
    line-height: 55px;
    letter-spacing: 1px;
    font-weight: 400;
    
    width: 100%;
    }

    @media only screen and (max-width: 768px) {
        .footer_title{
            color: #f6f7fb;
            font-size: 30px;
            line-height: 25px;
            letter-spacing: 0.5px;
            font-weight: 400;
            
            width: 100%;

    }
}

    .col-footer {
        .heading {
            color: $footer-heading-color;
            letter-spacing: 0;
            font-size: $footer-heading-font-size;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-bottom: 1rem;
        }
    }

    .nav .nav-item .nav-link,
    .footer-link {
        color: $footer-link-color !important;

        &:hover {
            color: $footer-link-hover-color !important;
        }
    }

    .list-unstyled li a {
        display: inline-block;
        padding: .125rem 0;
        color: $footer-link-color;
        font-size: $footer-link-font-size;

        &:hover {
            color: $footer-link-hover-color;
        }
    }

    .copyright {
        font-size: $font-size-sm;
    }

    .dropdown {
      .btn:not(:disabled):not(.disabled):active:focus,
      .btn:not(:disabled):not(.disabled).active:focus {
        box-shadow: none;
      }
    }
}

.footer-dark {
    .col-footer .heading {
        color: $white;
    }
}

.footer.has-cards {
    overflow: hidden;
    padding-top: 500px;
    margin-top: -420px;
    position: relative;
    background: transparent;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 600px;
        height: 2000px;
        background: theme-color("secondary");
        transform: skew(0,-8deg);
    }

    .container {
        pointer-events: auto;
        position: relative;
    }
}

.nav-footer {
    .nav-link {
        font-size: $font-size-sm;
    }

    .nav-item:last-child {
        .nav-link {
            padding-right: 0;
        }
    }
}


